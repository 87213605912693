import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useAuth } from '../context/AuthProvider'
import Path from '../routes/Path'
import SidebarLayout from '../components/SidebarLayout'
import { useNavigate } from 'react-router-dom'

export default function Home() {
  const { isAuthenticated, isLoading } = useAuth0()
  const { isAdmin, isReadOnlyUser } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated && isAdmin()) {
      navigate(Path.clients.path)
    } else if (isAuthenticated && isReadOnlyUser()) {
      // navigate(Path.assessments.path)
    }
  }, [isAdmin, isAuthenticated, isReadOnlyUser, navigate])

  return (
    <SidebarLayout>
      {!isLoading && (
        <div className="flex grow flex-col items-center justify-center gap-8 bg-purple-950 p-4">
          <div className="page-title">Welcome to Admin Hub</div>
        </div>
      )}
    </SidebarLayout>
  )
}
