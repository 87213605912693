import { eoiApiClient as apiClient } from './configs/AxiosClient'
import { LearnerReport } from './entities/learnerReport.entity'
import { Program } from './entities/program.entity'
import { Submission } from './entities/submission.entity'

export enum EmailTemplateType {
  learnerConfirmation = 'learnerConfirmation',
  managerConfirmation = 'managerConfirmation',
  managerConsent = 'managerConsent'
}

export const EoiAPI = {
  getPrograms: async (): Promise<Program[] | undefined> => {
    const response: any = await apiClient.get(`/programs`)
    return response.data
  },
  getMyPrograms: async (): Promise<Program[] | undefined> => {
    const response: any = await apiClient.get(`/programs/mine`)
    return response.data
  },
  getProgram: async (slug: string): Promise<Program | undefined> => {
    const response: any = await apiClient.get(`/programs/${slug}`)
    return response.data
  },
  getProgramById: async (programId: string): Promise<Program | undefined> => {
    const response: any = await apiClient.get(`/programs/id/${programId}`)
    return response.data
  },
  updateProgram: async (slug: string, body: any): Promise<Program | undefined> => {
    const response: any = await apiClient.patch(`/programs/${slug}/overwrite`, body)
    return response.data
  },
  exportReport: async (programId: string): Promise<any> => {
    const response: any = await apiClient.get(`/programs/${programId}/export`, {
      responseType: 'blob'
    })
    return response.data
  },
  getSubmissions: async (programId: string): Promise<Submission[] | undefined> => {
    const response: any = await apiClient.get(`/submissions/?programId=${programId}`)
    return response.data
  },
  getLearnerReports: async (programId: string): Promise<LearnerReport[] | undefined> => {
    const response: any = await apiClient.get(`/learner/results/${programId}`)
    return response.data
  },
  triggerManagerConfirmation: async (id: string): Promise<any> => {
    const response: any = await apiClient.post(`/learner/${id}/trigger-manager-confirmation`)
    return response.data
  },
  updateLearnerReport: async (id: string, body: any): Promise<LearnerReport | undefined> => {
    // Remove _id from body
    if (body._id) {
      delete body._id
    }
    const response: any = await apiClient.patch(`/learner/${id}`, body)
    return response.data
  },
  sendReminders: async (programId: string, ids: string[]): Promise<any> => {
    const response: any = await apiClient.post(`/learner/send-reminders`, { programId, ids })
    return response.data
  },
  inviteLearnersToCohort: async (ids: string[], availability: string): Promise<any> => {
    const response: any = await apiClient.post(`/learner/invite`, { ids, availability })
    return response.data
  },
  rejectLearnersFromCohort: async (ids: string[]): Promise<any> => {
    const response: any = await apiClient.post(`/learner/reject`, { ids })
    return response.data
  },
  addLearnerReport: async (body: any): Promise<LearnerReport | undefined> => {
    const response: any = await apiClient.post(`/learner`, body)
    return response.data
  },
  getEmailTemplate: async (programId: string, type: EmailTemplateType): Promise<any> => {
    const body = { programId, type }
    const response: any = await apiClient.post(`/learner/email-template`, body)
    return response.data
  }
}
